


















































































































































































































































.page-wallet {
  .list-coin-seciton {
    width: 100%;
    min-height: 250px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .box-coin {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      .coin {
        box-shadow: 0px 0px 10px 2px #00000038;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 15px 0;
        margin-bottom: 15px;
        margin: 0 auto 15px;
        position: relative;
        width: calc(100% - 20px);
        // min-width: 410px;

        .left {
          position: absolute;
          top: 10px;
          right: 10px;
          .icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;
            width: 100%;
            border-radius: 50%;
            box-shadow: 0px 2px 10px 2px #0000006e;
            img {
              width: 100%;
              max-width: 40px;
              height: auto;
              filter: drop-shadow(0px 0px 10px 2px #00000050);
            }
          }
        }
        .right {
          flex: 1 1 auto;
          padding-left: 5px;
          .coin-name {
            font-weight: 600;
            font-size: 20px;
            color: #2f394e;
            text-align: left;
            padding-left: 10px;
          }
          .coin-info {
            padding-left: 10px;
            .title {
              font-weight: 600;
              color: #2f394e;
              opacity: 0.9;
              text-align: center;
              text-transform: uppercase;
              margin-right: 5px;
              font-size: 15px;
            }
            .value {
              font-weight: 600;
              font-size: 19px;
              color: #2f394e;
              text-align: center;
            }
          }
          .coin-action {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            button {
              flex: 1;
              min-width: 90px;
              max-width: 120px;
              height: 40px;
              box-shadow: 0 2px 6px 0 #ccc;
              font-weight: 600;
              margin: 5px 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover,
              &:focus,
              &:focus-visible {
                box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
    }
    @media (max-width: 1600px) {
      .box-coin {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (max-width: 991px) {
      .box-coin {
        flex: 0 0 50%;
        max-width: 50%;
        .coin {
          width: calc(100% - 10px);
          max-width: 520px;
        }
      }
    }
    @media (max-width: 767px) {
      .box-coin {
        flex: 0 0 100%;
        max-width: 100%;
        .coin {
          width: 100%;
          max-width: 520px;
        }
      }
    }
  }
  .wallet-action {
    width: 100%;
    min-height: 250px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    position: relative;
  }
  .history-box {
    box-shadow: 0px 0px 10px 2px #00000038;
    background-color: #fff;
    min-height: 250px;
    border-radius: 10px;
    padding: 15p;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #2f394e;
      text-align: left;
      padding: 10px 15px;
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}
.table-reponsive {
  width: 100%;
  overflow-x: auto;
}
